import React, { useState } from "react";
// import { Draggable, Droppable } from "react-beautiful-dnd";

import useModal from "foundation/modals/useModal";
import useCreateRecipe from "cooking/hooks/useCreateRecipe";

import TextInput from "foundation/components/forms/TextInput";
import PlusButton from "foundation/components/PlusButton";
import IngredientDisplay from "cooking/components/IngredientDisplay";
import LinkIcon from "foundation/svgComponents/LinkIcon";
import AvocadoDetailsIcon from "cooking/svgs/AvocadoDetailsIcon";
import X from "foundation/svgComponents/X";

import { parseIngredientText } from "cooking/helpers/ingredientParser";
import { RecipeIngredient } from "cooking/models/types";
import MapRecipeIngredientToIngredientModal from "./MapRecipeIngredientToIngredientModal";

type ComplexRecipeIngredientsProps = {
    ingredients: RecipeIngredient[];
    setIngredients: (ingredients: RecipeIngredient[]) => void;
};

const ComplexRecipeIngredients: React.FC<ComplexRecipeIngredientsProps> = ({
    ingredients,
    setIngredients,
}) => {
    const { newModal, closeModal } = useModal();

    const [newIngredientText, setNewIngredientText] = useState("");
    const [newIngredientId, setNewIngredientId] = useState(-1);

    const { handleIngredientLinkClick, ingredientBeingLinked } =
        useCreateRecipe(false);

    const addNewIngredient = () => {
        const { name, quantity, unit } = parseIngredientText(newIngredientText);

        const newIngredient: RecipeIngredient = {
            id: newIngredientId,
            name,
            quantity,
            unit,
            originalText: newIngredientText,
        };

        setIngredients([...ingredients, newIngredient]);
        setNewIngredientId(newIngredientId - 1);

        setNewIngredientText("");
    };

    const removeIngredient = (id: number) => {
        setIngredients(ingredients.filter((i) => i.id !== id));
    };

    const handleNewIngredientKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            event.preventDefault();
            addNewIngredient();
        }
    };

    const handleMapToIngredient = (ingredient: RecipeIngredient) => {
        newModal(
            <MapRecipeIngredientToIngredientModal
                recipeIngredient={ingredient}
                handleIngredientClick={(ingredientToLinkToId: number) => {
                    const updatedRi = { ...ingredient };
                    updatedRi.ingredient = ingredientToLinkToId;

                    const newIngredients = ingredients.map((i) =>
                        i.id === updatedRi.id ? updatedRi : i
                    );
                    setIngredients(newIngredients);

                    closeModal();
                }}
            />
        );
    };

    return (
        <section
            id="complex-recipe-ingredients-section"
            className="border-theme-border border-[2px] border-solid px-3 pb-3 pt-1 mt-1 mx-1 w-fit min-w-[400px]"
        >
            <h2>Ingredients</h2>
            {ingredients.length > 0 && (
                <ul className="mb-2 pl-0">
                    {ingredients.map((i, index) => {
                        const isBeingLinked =
                            ingredientBeingLinked?.id === i.id;

                        return (
                            <li
                                className={`flex items-center gap-[8px] ${isBeingLinked ? "relative z-10" : ""
                                    }`}
                                key={index}
                            >
                                <IngredientDisplay ingredient={i} />
                                <button
                                    type="button"
                                    onClick={() => handleIngredientLinkClick(i)}
                                    title="Link Ingredient to Step"
                                >
                                    <LinkIcon size="14px" />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => handleMapToIngredient(i)}
                                    title="Link to Ingredient"
                                    className="text-theme-text"
                                >
                                    <AvocadoDetailsIcon
                                        size="14px"
                                        filledVersion={!!i.ingredient}
                                    />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => removeIngredient(i.id)}
                                    title="Remove ingredient"
                                    disabled={isBeingLinked}
                                    className="disabled:opacity-20"
                                >
                                    <X size="14px" />
                                </button>
                            </li>
                        );
                    })}
                </ul>
            )}
            <div className="flex gap-[5px]">
                <TextInput
                    name="add-ingredient-box"
                    className="grow max-w-[400px]"
                    value={newIngredientText}
                    onChange={setNewIngredientText}
                    onKeyDown={handleNewIngredientKeyDown}
                />
                <PlusButton
                    title="Add new ingredient"
                    size="20px"
                    onClick={addNewIngredient}
                />
            </div>
        </section>
    );
};

// DnD not working well. Abandoning for now.
/* const ComplexRecipeIngredients: React.FC<ComplexRecipeIngredientsProps> = ({
    ingredients,
    setIngredients,
}) => {
    const [newIngredientText, setNewIngredientText] = useState("");
    const [newIngredientId, setNewIngredientId] = useState(-1);

    const addNewIngredient = () => {
        const { name, quantity, unit } = parseIngredientText(newIngredientText);

        const newIngredient: RecipeIngredient = {
            id: newIngredientId,
            name,
            quantity,
            unit,
            originalText: newIngredientText,
        };

        setIngredients([...ingredients, newIngredient]);
        setNewIngredientId(newIngredientId - 1);

        setNewIngredientText("");
    };

    const removeIngredient = (id: number) => {
        setIngredients(ingredients.filter((i) => i.id !== id));
    };

    const handleNewIngredientKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            event.preventDefault();
            addNewIngredient();
        }
    };

    return (
        <Droppable droppableId="ingredients-section">
            {(droppableProvided) => (
                <section
                    id="complex-recipe-ingredients-section"
                    className="border-theme-border border-[2px] border-solid px-3 pb-3 pt-1 mt-1 mx-1 w-fit min-w-[400px]"
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                >
                    <h2>Ingredients</h2>
                    {ingredients.length > 0 && (
                        <ul className="mb-2 pl-0">
                            {ingredients.map((i, index) => (
                                <Draggable
                                    draggableId={`ingredient_${i.id}`}
                                    index={index}
                                    key={index}
                                >
                                    {(provided) => (
                                        <li
                                            className="flex items-center gap-[8px]"
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                        >
                                            <IngredientDisplay ingredient={i} />
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    removeIngredient(i.id)
                                                }
                                                title="Remove ingredient"
                                            >
                                                <X size="14px" />
                                            </button>
                                        </li>
                                    )}
                                </Draggable>
                            ))}
                            {droppableProvided.placeholder}
                        </ul>
                    )}
                    <div className="flex gap-[5px]">
                        <TextInput
                            name="add-ingredient-box"
                            className="grow max-w-[400px]"
                            value={newIngredientText}
                            onChange={setNewIngredientText}
                            onKeyDown={handleNewIngredientKeyDown}
                        />
                        <PlusButton
                            title="Add new ingredient"
                            size="20px"
                            onClick={addNewIngredient}
                        />
                    </div>
                </section>
            )}
        </Droppable>
    );
}; */

export default ComplexRecipeIngredients;
