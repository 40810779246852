import React from "react";
import { useImmer } from "use-immer";
import useModal from "foundation/modals/useModal";

import Checkbox from "foundation/components/forms/Checkbox";
import EditTags from "foundation/components/forms/EditTags";

import { KitchenIngredient, Tag } from "cooking/models/types";
import { curriedStateSlice } from "helpers/state.helpers";

type KiDetailsModalProps = {
    kitchenIngredient: KitchenIngredient;
    allExistingTags?: Tag[];
    handleSave: (ki: KitchenIngredient) => void;
    handleRemove: (ki: KitchenIngredient) => void;
};

// TODO add edit name
const KitchenIngredientDetailsModal: React.FC<KiDetailsModalProps> = ({
    kitchenIngredient: _kitchenIngredient,
    allExistingTags,
    handleSave,
    handleRemove,
}) => {
    const { closeModal } = useModal();

    const [kitchenIngredient, setKitchenIngredient] =
        useImmer(_kitchenIngredient);
    const updateSlice = curriedStateSlice(setKitchenIngredient);
    const updateIngredientSlice = curriedStateSlice(updateSlice("ingredient"));

    console.log("kitchenIngredient:", kitchenIngredient);

    const tagStrings = kitchenIngredient.ingredient.tags.map(
        (tag) => tag.content
    );

    return (
        <div className="flex flex-col gap-[20px] w-[300px]">
            <h1>{kitchenIngredient.ingredient.name}</h1>

            <div className="flex flex-col gap-[10px] min-w-[150px] w-full">
                <Checkbox
                    text="In Stock"
                    value={kitchenIngredient.inStock}
                    boxEnd
                    inputClassName="!ml-auto border-b-[1px] border-solid"
                    labelClassName="text-[18px]"
                    onClick={() =>
                        console.log('TODO toggle local "in stock" state')
                    }
                />
                <Checkbox
                    text="Keep Stocked"
                    value={false}
                    boxEnd
                    inputClassName="!ml-auto"
                    labelClassName="text-[18px]"
                    onClick={() =>
                        console.log("TODO toggle Keep Stocked (+ add field)")
                    }
                />
                <Checkbox
                    text="Low?"
                    value={false}
                    boxEnd
                    inputClassName="!ml-auto"
                    labelClassName="text-[18px] text-theme-button-danger"
                    onClick={() => console.log("TODO toggle Low (+ add field)")}
                />

                <EditTags
                    tags={tagStrings}
                    allAvailableTags={allExistingTags}
                    setTags={(newTags) =>
                        updateIngredientSlice(
                            "tags",
                            newTags.map((tagString) => {
                                if (tagStrings.includes(tagString)) {
                                    // If we already had this tag, preserve its data (i.e. its id)
                                    const oldTag =
                                        kitchenIngredient.ingredient.tags.find(
                                            (tag) => tag.content === tagString
                                        );
                                    if (oldTag) {
                                        return oldTag;
                                    }
                                }
                                return { content: tagString };
                            })
                        )
                    }
                />
            </div>

            <div className="flex gap-[15px]">
                <button
                    onClick={() => {
                        handleSave(kitchenIngredient);
                        closeModal();
                    }}
                    className="btn btn-primary"
                >
                    Save
                </button>
                <button
                    onClick={() => {
                        handleRemove(kitchenIngredient);
                        closeModal();
                    }}
                    className="btn btn-danger"
                >
                    Remove
                </button>

                <button
                    onClick={closeModal}
                    className="btn btn-secondary ml-auto"
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default KitchenIngredientDetailsModal;
