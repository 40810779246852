import React, { useState } from "react";

import TextInput from "foundation/components/forms/TextInput";
import ComplexRecipeIngredients from "cooking/components/ComplexRecipeIngredients";
import ComplexStepSection from "cooking/components/ComplexStepSection";

import useCreateRecipe from "cooking/hooks/useCreateRecipe";

type CreateRecipeProps = {};

const CreateRecipeNew: React.FC<CreateRecipeProps> = () => {
    const {
        recipe,
        updateRecipe,
        handleSubmit: _handleSubmit,
        isIngredientLinkActive,
        clearIngredientLink,
    } = useCreateRecipe();

    const [isSaving, setIsSaving] = useState(false);

    const handleSubmit = async (e: any) => {
        setIsSaving(true);
        await _handleSubmit(e);
        setIsSaving(false);
    };

    if (!recipe) {
        return <div>Loading...</div>;
    }

    console.log("🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆");
    console.log("recipe:", { ...recipe });

    return (
        <form
            onSubmit={handleSubmit}
            autoComplete="off"
            className="flex flex-col mx-auto gap-[10px] relative"
        >
            {isIngredientLinkActive && (
                <button
                    type="button"
                    className="absolute z-[1] h-full w-full opacity-80 bg-theme-background"
                    onClick={clearIngredientLink}
                />
            )}
            <TextInput
                name="name"
                placeholder="Recipe Name"
                displayLabel="Recipe name"
                extraInputClasses="h-[40px]"
                value={recipe.name}
                onChange={(newName) => updateRecipe("name", newName)}
                autoComplete={false}
            />

            <ComplexRecipeIngredients
                ingredients={recipe.ingredients}
                setIngredients={(i) => updateRecipe("ingredients", i)}
            />

            <ComplexStepSection
                // @ts-ignore
                steps={recipe.steps}
                setSteps={(s) => updateRecipe("steps", s)}
            />

            <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary mt-4 w-fit disabled:opacity-70"
                disabled={isSaving}
            >
                {!isSaving && "Save"}
                {isSaving && "Saving..."}
            </button>
        </form>
    );
};

export default CreateRecipeNew;
