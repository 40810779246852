import React, { useState } from "react";
import { cloneDeep } from "lodash";
import { useApi } from "common/hooks/useApi";
import { callApiMethod } from "helpers/api.helpers";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import useModal from "foundation/modals/useModal";

import KitchenIngredientDetailsModal from "./KitchenIngredientDetailsModal";
import TextInput from "foundation/components/forms/TextInput";

import { Ingredient, KitchenIngredient, Tag } from "cooking/models/types";

type KitchenProps = {};

// TODO search / filter
const Kitchen: React.FC<KitchenProps> = () => {
    const { newModal } = useModal();
    const { currentUser } = useCurrentUser();

    const [searchString, setSearchString] = useState("");

    const {
        data: _kitchenIngredients,
        updateItem: updateKitchenIngredient,
        deleteItem: removeKitchenIngredient,
    } = useApi<KitchenIngredient>("kitchen_ingredient");

    const { data: allIngredients } = useApi<Ingredient>("ingredient");

    console.log("🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆");
    console.log("allIngredients:", allIngredients);

    const { data: tags, refetch: refetchTags } = useApi<Tag>(
        "tag?type=ingredient"
    );

    let kitchenIngredients = cloneDeep(_kitchenIngredients);
    kitchenIngredients?.sort((a, b) =>
        a.ingredient.name.localeCompare(b.ingredient.name)
    );
    if (searchString) {
        kitchenIngredients = kitchenIngredients.filter((ki) =>
            ki.ingredient.name
                .toLowerCase()
                .includes(searchString.toLowerCase())
        );
    }

    // sort by tags + ki's with no tags
    const ingredientsInKitchen: number[] = [];
    const NO_TAGS_KEY = "no tags";
    const kiByTag: { [tag: string]: KitchenIngredient[] } = {};
    kitchenIngredients?.forEach((ki) => {
        ingredientsInKitchen.push(ki.ingredient.id);

        const ingredientTags = ki.ingredient.tags;

        const appendToMaybeArray = (key: string) => {
            if (!kiByTag[key]) kiByTag[key] = []; // create the array if it doesn't exist
            kiByTag[key].push(ki);
        };

        if (ingredientTags.length === 0) {
            appendToMaybeArray(NO_TAGS_KEY);
        } else {
            ingredientTags.forEach((tag) => {
                appendToMaybeArray(tag.content);
            });
        }
    });

    const ingredientsByTag: {
        [tag: string]: (Ingredient & { isInKitchen: boolean })[];
    } = {};
    allIngredients?.forEach((i) => {
        const ingredientTags = i.tags;

        const appendToMaybeArray = (key: string) => {
            if (!ingredientsByTag[key]) ingredientsByTag[key] = []; // create the array if it doesn't exist
            ingredientsByTag[key].push({
                ...i,
                isInKitchen: !!kitchenIngredients?.find(
                    (ki) => ki.ingredient.id === i.id
                ),
            });
        };

        if (ingredientTags.length === 0) {
            appendToMaybeArray(NO_TAGS_KEY);
        } else {
            ingredientTags.forEach((tag) => {
                appendToMaybeArray(tag.content);
            });
        }
    });

    console.log("ingredientsByTag:", ingredientsByTag);

    // const orderedTags = Object.keys(kiByTag);
    const orderedTags = Object.keys(ingredientsByTag);
    orderedTags.sort();

    const openKitchenIngredientDetailsModal = (ki: KitchenIngredient) => {
        newModal(
            <KitchenIngredientDetailsModal
                kitchenIngredient={ki}
                allExistingTags={tags}
                handleSave={async (updatedKi) => {
                    await updateKitchenIngredient(updatedKi);
                    refetchTags();
                }}
                handleRemove={removeKitchenIngredient}
            />
        );
    };

    const createIngredientAndAddToStock = async () => {
        const {
            data: { success, kitchen_ingredient: kitchenIngredient },
        } = await callApiMethod("cooking/createIngredientAndAddToStock", {
            name: searchString,
            userId: currentUser.id,
        });

        // if (success) {
        //     setInKitchenIngredients([
        //         ...inKitchenIngredients,
        //         kitchenIngredient,
        //     ]);
        //     setSearchString("");
        // }
    };

    return (
        <section className="flex flex-col gap-[30px] w-full">
            <TextInput
                displayLabel="Search"
                value={searchString}
                onChange={(v) => setSearchString(v)}
                placeholder="Filter or add..."
                onEnter={createIngredientAndAddToStock}
            />

            <div className="flex gap-[60px] w-full flex-wrap">
                {orderedTags?.map((tag) => (
                    <div
                        key={tag}
                        className={`flex flex-col gap-[20px] shrink-0 ${tag === NO_TAGS_KEY ? "order-1" : ""
                            }`}
                    >
                        <h2 className="capitalize">{tag}</h2>
                        <ul className="p-0 w-full">
                            {ingredientsByTag[tag]?.map((ki, index) => (
                                <li
                                    key={index}
                                    className="border-b-[1px] border-solid border-theme-border last:border-b-0"
                                >
                                    <button
                                        className="py-[5px] px-[10px] text-[18px] w-full text-left"
                                        onClick={() =>
                                            openKitchenIngredientDetailsModal(
                                                ki as any
                                            )
                                        }
                                    >
                                        {ki.name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}

                <div className="flex flex-col gap-[20px] shrink-0">
                    <h2 className="capitalize">Other Ingredients</h2>
                    <ul className="p-0 w-full">
                        {allIngredients?.map((ingredient) => {
                            if (ingredientsInKitchen.includes(ingredient.id))
                                return undefined;

                            return (
                                <li
                                    key={ingredient.id}
                                    className="border-b-[1px] border-solid border-theme-border last:border-b-0"
                                >
                                    <button
                                        className="py-[5px] px-[10px] text-[18px] w-full text-left"
                                    // onClick={() =>
                                    //     openKitchenIngredientDetailsModal(
                                    //         ki
                                    //     )
                                    // }
                                    >
                                        {ingredient.name}
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default Kitchen;
