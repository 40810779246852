import api from "helpers/api.helpers";
import { cloneDeep } from "lodash";
import { useGetApi } from "./useGetApi";

export function useApi<T extends { id?: number }>(
    url: string,
    postprocess?: (input: T[]) => T[],
    skip: boolean = false
) {
    const urlWithSlash = url.endsWith("/") ? url : `${url}/`;

    const { data, refetch, isLoading, appendData, setData } = useGetApi<T[]>(
        url,
        {
            postprocess,
            skip,
        }
    );

    const createItem = (item: T) => {
        // TODO be optimistic...
        api.post(urlWithSlash, item)
            .then((res) => {
                appendData(res.data);
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const deleteById = (id: number) => {
        const oldData = cloneDeep(data);
        const newData = data.filter((item: T) => item?.id !== id);
        setData(newData);

        api.delete(`${urlWithSlash}${id}`).catch((e) => {
            console.error("Failed to delete:", e);
            setData(oldData);
        });
    };

    const deleteItem = (item: T) => {
        deleteById(item.id);
    };

    const updateItem = async (item: T) => {
        // Apparently didn't work to trigger a re-render somwhere?
        const oldData = cloneDeep(data);
        const itemIndex = data.findIndex((d) => d.id === item.id);

        if (itemIndex === -1) return; // something went wrong, abort
        data[itemIndex] = item;
        setData(cloneDeep(data));

        try {
            await api.put(urlWithSlash, item);
        } catch (e) {
            console.error("Error updating item", e);
            setData(oldData);
        }
    };

    return {
        data,
        createItem,
        updateItem,
        deleteById,
        deleteItem,
        refetch,
        isLoading,
    };
}
