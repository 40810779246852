import React, { CSSProperties } from "react";
import TextareaAutosize from "react-textarea-autosize";

type TextInputProps = {
    /** The name of the field + the key for the value in request.POST */
    name?: string;

    /** The value of the text input */
    value?: string | number;

    /** Handle text input change */
    onChange?: (newValue: string) => void;

    onKeyDown?: (e: React.KeyboardEvent) => void;

    onEnter?: () => void;

    /** placeholder text for the input */
    placeholder?: string;

    /** The label to display for the selection */
    displayLabel?: string;

    /** The type of the text input (e.g. text, number) */
    type?: string;

    /** If the field is required */
    required?: boolean;

    /** The max length of the field */
    maxLength?: number;

    /** Extra classes for the containing div */
    className?: string;

    /** Extra classes for the input */
    extraInputClasses?: string;

    defaultInputClasses?: string;

    /** Extra classes for the label */
    labelClasses?: string;

    /** Whether or not to display this input. Defaults to true */
    display?: boolean;

    /** A reference to the input element */
    innerRef?: any;

    /** Whether the input should be focused when the page is first loaded */
    autoFocus?: boolean;

    /** Number to increment for number inputs */
    step?: number;

    /** Minimum value for number inputs */
    min?: number;

    /** Maximum value for number inputs */
    max?: number;

    /** Whether autocomplete is enabled (+ lastpass icon) */
    autoComplete?: boolean;

    /** This is a text input that isn't controlled by state */
    isUncontrolledInput?: boolean;

    /** inverse color scheme */
    inverse?: boolean;

    /** input element style */
    style?: CSSProperties;
    children?: React.ReactNode;
};

const TextInput: React.FC<TextInputProps> = ({
    name,
    value,
    placeholder,
    displayLabel,
    type = "text",
    required,
    maxLength,
    className = "form-group",
    extraInputClasses = "form-control-sm",
    defaultInputClasses = "form-control",
    labelClasses = "",
    onChange,
    onKeyDown,
    onEnter,
    innerRef,
    display = true,
    children,
    autoFocus = false,
    step,
    min,
    max,
    autoComplete = true,
    isUncontrolledInput = false,
    inverse,
    style,
}) => {
    if (!display) {
        return <></>;
    }

    value = value ?? "";

    const id = `id-${name}`;
    return (
        <div
            id={`${name}-field`}
            className={`${className ?? ""} ${autoComplete ? "" : "no-lastpass"
                } `}
        >
            {displayLabel && (
                <label htmlFor={id} className={`${labelClasses} mb-1`}>
                    {displayLabel}:
                </label>
            )}
            {type === "textarea" ? (
                <TextareaAutosize
                    id={id}
                    name={name}
                    className={`${defaultInputClasses} ${extraInputClasses ?? ""
                        } ${inverse && "bg-transparent !text-theme-input-background"
                        }`}
                    maxLength={maxLength}
                    onChange={
                        onChange ? (e) => onChange(e.target.value) : undefined
                    }
                    ref={innerRef}
                    value={
                        value === null || isUncontrolledInput
                            ? undefined
                            : value
                    }
                    required={required}
                    autoFocus={autoFocus}
                    autoComplete={autoComplete ? "on" : "off"}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && onEnter) {
                            onEnter();
                        }
                        onKeyDown?.(e);
                    }}
                />
            ) : (
                <input
                    id={id}
                    type={type}
                    name={name}
                    className={`${defaultInputClasses} ${extraInputClasses ?? ""
                        } ${inverse && "bg-transparent !text-theme-input-background"
                        }`}
                    maxLength={maxLength}
                    onChange={
                        onChange ? (e) => onChange(e.target.value) : undefined
                    }
                    ref={innerRef}
                    value={
                        value === null || isUncontrolledInput
                            ? undefined
                            : value
                    }
                    placeholder={placeholder}
                    required={required}
                    autoFocus={autoFocus}
                    step={type === "number" ? step : undefined}
                    min={type === "number" ? min : undefined}
                    max={type === "number" ? max : undefined}
                    autoComplete={autoComplete ? "on" : "off"}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && onEnter) {
                            onEnter();
                        }
                        onKeyDown?.(e);
                    }}
                    style={style}
                />
            )}

            {children}
        </div>
    );
};

export default TextInput;
